/**
 * This mixin implements CRUD using API calls to Laravel
 */

// Utilities
import moment from "moment";
// Utilities
import { mapState, mapMutations } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState({
      storeNotifications: "notifications",
      totalNotifications: "totalNotifications",
      lastNotification: "lastNotification",
    }),
    notifications() {
      return this.storeNotifications.map(this.process);
    },
  },
  methods: {
    ...mapMutations({
      setNotifications: "SET_NOTIFICATIONS",
      updateNotifications: "UPDATE_NOTIFICATIONS",
    }),
    mark_read(n) {
      this.put("/notifications/" + n.id, { read: 1 }).then((data) => {
        this.setNotifications(data);
      });
    },
    process(n) {
      n.color = "info";
      n.icon = "mdi-bell";
      n.date = moment(n.created_at).format("lll");

      if (n.data.text) {
        n.text = this.$t(n.data.text);
      } else {
        switch (n.type) {
          case "App\\Notifications\\UserCreated":
            n.text = this.$t("notifications.New user") + ": " + n.data.name;
            n.icon = "mdi-account-plus";
            n.color = "warning";
            n.tag = 'user' + n.data.id;
            break;
          case "App\\Notifications\\TicketEntryCreated":
            n.text =
              this.$t("notifications.New ticket entry") + " #" + n.data.ticket;
            n.icon = "mdi-ticket-confirmation";
            n.color = "warning";
            n.link = "/pages/tickets/" + n.data.ticket;
            n.tag = 'ticket' + n.data.id;
            break;
        }
      }
      return n;
    },
  },
};
