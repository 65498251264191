<template>
  <v-container id="alerts" fluid tag="section">
    <v-card>
      <v-card-text>
        <base-subheading :subheading="$t('notifications.Latest')" />

        <base-notification
          v-for="item in notifications"
          v-on:input="mark_read(item)"
          :key="'not' + item.id"
          :color="item.color"
          :icon="item.icon"
          >{{ item.date }} - {{ item.text }}
        </base-notification>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import notifications from "mixins/notifications";
export default {
  name: "Notifications",
  data: () => ({}),
  mixins: [notifications],
};
</script>
